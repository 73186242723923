//export const server = 'http://192.168.1.62:8000';
// export const server = 'http://ec2-18-221-193-191.us-east-2.compute.amazonaws.com:8000';
//export const server = 'http://localhost:8000';
//export const server = 'https://api-dev.totalcarpet.ro';
export const server = 'https://api.totalcarpet.ro';

export const GOOGLE_API_KEY = 'AIzaSyAfyk6ETAppTZmzV3cg73k_oiweR0VeQPY'

export const customModalStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        maxHeight: '100vh',
    },
};

export const expenseSubcategories = {
    "Salariați": ["-", "Salarii", "Taxe"],
    "Materii prime": ["-"],
    "Utilități": ["-"],
    "Altele": ["-"],
}
